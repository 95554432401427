import { Node, Schema } from 'prosemirror-model';
import { findBlockNodes, findChildren, findTextNodes, } from '@remirror/core';
import { isMention, isBullet, isNodeNYCTRoute } from './doc-utils';
import { SHUTTLE_TRAINS } from './text-replacements';
// TODO: This should be MentionType, which should
// be pulled out of the web package
// https://app.shortcut.com/mta-mercury/story/7522/
export var BulletType;
(function (BulletType) {
    BulletType["Route"] = "ROUTE";
    BulletType["Trip"] = "TRIP";
    BulletType["Stop"] = "STOP";
    BulletType["ADA"] = "ADA";
    BulletType["ShuttleBus"] = "SHUTTLE_BUS";
    BulletType["Airplane"] = "AIRPLANE";
})(BulletType || (BulletType = {}));
export const fromJSONToNode = (schemaJSON, docJSON) => {
    const schema = new Schema(schemaJSON);
    const node = Node.fromJSON(schema, docJSON);
    return node;
};
export const formatMessageDoc = (doc, { bracketRoutes = false, bracketCustomIcons = false, bracketShuttleTrains = false, enforceSpacesBetweenEntities = true, outputHTML = false, replacements, bracketRoutesAttributesConditional, }) => {
    let formattedText = '';
    const blockNodes = findBlockNodes({
        node: doc,
    });
    blockNodes.forEach((blockNode, idx) => {
        if (isBullet(blockNode)) {
            return;
        }
        const textNodes = findTextNodes(blockNode);
        // a blockNode might contain multiple hard breaks
        const hardBreakNodes = findChildren({
            predicate: (el) => el.node.type.name === 'hardBreak',
            node: blockNode.node,
        });
        textNodes.forEach((textNode, idx) => {
            if (!outputHTML) {
                // hard breaks need to be translated into newlines when
                // the expected output is in plaintext.
                //
                // NOTE: this should be treated as a quick fix and not a long-term solution.
                // `formatMessageDoc` is a brittle, but critical parsing layer that lays
                // between prosemirror content and GTFS generation. This should be a good
                // candidate for refactoring.
                const adjacentHardBreaks = hardBreakNodes
                    .filter((node) => {
                    return Math.abs(node.pos - textNode.pos) === 1;
                })
                    .map((_node) => '\n');
                formattedText += adjacentHardBreaks.join('');
            }
            if (!isMention(textNode)) {
                formattedText += textNode.node.text;
                return;
            }
            const attributes = textNode.node.marks[0].attrs;
            if (!attributes['data-bullet-title']) {
                formattedText += textNode.node.text;
                return;
            }
            const type = attributes['data-bullet-type'];
            const isRoute = type === BulletType.Route;
            const isRouteToBracket = (bracketRoutes ||
                (bracketRoutesAttributesConditional &&
                    bracketRoutesAttributesConditional(attributes))) &&
                isRoute;
            // what qualifies as a node belonging to nyct-bus route or nyct-subway route?
            const isNYCTRoute = attributes['data-bullet-id'] &&
                attributes['data-bullet-id'].startsWith('MTA') &&
                isRoute;
            if (isNYCTRoute && isRoute && !isRouteToBracket) {
                const replaceSpace = idx > 1 &&
                    isMention(textNode) &&
                    !textNodes[idx - 1].node.text?.replace(/\s/g, '').length &&
                    isNodeNYCTRoute(textNodes[idx - 2]);
                if (replaceSpace) {
                    formattedText = formattedText.trim();
                    formattedText += '/';
                }
            }
            // variables that matter for cross-agency
            // is it a route
            // is it a nyct entity (sub or bus)
            // is the next node a cross agency node
            // ex MNR current agency
            // MNR station & 4/5/6 & MNR route
            // it should look like <MNR station> 4/5/6 <MNR Route>
            //
            const id = (() => {
                if (type === BulletType.Route) {
                    return attributes['data-bullet-route-id'] || undefined;
                }
                if (type === BulletType.Trip) {
                    return attributes['data-bullet-trip-id'] || undefined;
                }
                if (type === BulletType.Stop) {
                    return attributes['data-bullet-stop-id'] || undefined;
                }
                return undefined;
            })();
            const title = attributes['data-bullet-title'];
            const text = (id && replacements?.[id]) ?? title;
            const addBracketToShuttleTrain = bracketShuttleTrains && isRoute && SHUTTLE_TRAINS.includes(text);
            if (isRouteToBracket || addBracketToShuttleTrain) {
                formattedText += id?.startsWith('mtasbwy') ? `[${text}]` : text;
            }
            else if ((type === BulletType.Stop || type === BulletType.Trip) &&
                outputHTML) {
                formattedText += `<b>${text}</b>`;
            }
            else if (bracketCustomIcons) {
                switch (type) {
                    case BulletType.ADA:
                        formattedText += '[accessibility icon]';
                        break;
                    case BulletType.Airplane:
                        formattedText += '[airplane icon]';
                        break;
                    case BulletType.ShuttleBus:
                        formattedText += `[shuttle bus icon]`;
                        break;
                    default:
                        formattedText += text;
                }
            }
            else {
                formattedText += text;
            }
            /*
              Add a space after this entity if
              enforceSpacesBetweenEntities is true AND
              there is more text after this entity AND
              the next bit of text is not a space or in a set of sentence-ending punctuation AND
              (
                we're not bracketing routes OR
                the current entity is not a route OR
                the current and next entity are not immediately adjacent
              )
            */
            const isLastNode = idx === textNodes.length - 1;
            if (enforceSpacesBetweenEntities && !isLastNode) {
                const nextTextNode = textNodes[idx + 1];
                if (nextTextNode?.node?.text) {
                    const nextText = nextTextNode.node.text;
                    const nextAttributes = nextTextNode.node.marks[0]?.attrs;
                    const validTextFollows = nextText.length !== 0 && !/^[\s.,;!\-—–)?\/]/.test(nextText);
                    const isAdjacentMention = isMention(nextTextNode);
                    if (validTextFollows && (!isRouteToBracket || !isAdjacentMention)) {
                        const isNextNodeRoute = nextAttributes &&
                            nextAttributes['data-bullet-type'] === BulletType.Route;
                        const nextRouteIsNYCT = isNextNodeRoute && isNodeNYCTRoute(nextTextNode);
                        // oh my god the conditions for this
                        formattedText +=
                            isNextNodeRoute &&
                                isNYCTRoute &&
                                nextRouteIsNYCT &&
                                !isRouteToBracket
                                ? '/'
                                : ' ';
                    }
                }
            }
        });
        if (idx !== blockNodes.length - 1) {
            formattedText += outputHTML ? '<br/>' : '\n';
        }
    });
    return formattedText
        .replace(/\u00a0/g, ' ') // replace all &nbsp with spaces
        .replace(/\u200c/g, '') // remove all zero width non-joiners
        .trim();
};
export default formatMessageDoc;
