/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import Button from 'components/common/Button';
import {
  SearchableLockableUploads,
  SearchableLockableUploadsVariables,
  SearchableLockableUploads_searchLockableUploads_SearchLockableUploadsResult_results_LockableUpload as SearchableLockableUploads_searchLockableUploads_results,
} from 'generated/SearchableLockableUploads';
import { useFeatureFlag } from 'hooks/useFeatureFlag';

import { Thumbnail } from 'ui-kit/media-library';
import AppLink, { ApplinkStyles } from 'components/common/app-link';
import { useFeedId } from 'contexts/FeedId';
import useQueryParams from '../../../hooks/useQueryParams';

import * as Layout from '../../common/layout';
import Loader from '../../common/skeletons/PageWithTable';
import Pagination from '../../common/Pagination';
import { ThemeType } from '../../../theme';

import Select from '../../common/form-elements/Select';
import { filterInput } from '../../common/styles';
import SearchInput from '../../common/form-elements/search-input';

import PageMeta from '../../common/PageMeta';
import PageHeading, { ButtonList } from '../../scaffolding/PageHeading';

import NoMessages from '../../common/NoMessages';

import {
  WEB_NO_ASSETS_MESSAGE,
  WEB_NO_ASSETS_HEADING,
  WEB_NO_ASSETS_MESSAGE_FILTERS,
  WEB_NO_ASSETS_HEADING_FILTERS,
} from '../../../constants/empty-states';
import { FeatureFlagName } from 'generated/global-types';

const SearchableLockableUploadsQuery = loader(
  '../../../graphql/SearchableLockableUploads.gql',
);

const QUERY_PARAM_KEYS = {
  search: 'search',
  mime: 'mime',
  dimension: 'dimension',
};

const AVAILABLE_MIME_TYPES = [
  { value: '', label: 'All File Types' },
  { value: 'image/', label: 'Images' },
  { value: 'video/', label: 'Videos' },
  { value: 'application/zip', label: 'Zip' },
];

const AVAILABLE_SCREEN_RESOLUTIONS = [
  { value: '', label: 'All Screen Resolutions' },
  { value: '1080x1920', label: '1080 x 1920px' },
  { value: '1920x1080', label: '1920 x 1080px' },
];

const setQueryParams = (
  history: any,
  params: { [key: string]: string | number },
  scrollToTop = true,
) => {
  const query = new URLSearchParams(history.location.search);
  Object.entries(params).forEach(([name, value]) => {
    query.set(name, value as string);
  });
  history.push({ ...history.location, search: query.toString() });
  if (scrollToTop) {
    window.scrollTo(0, 0);
  }
};

const PAGE_SIZE = 12;

const LockedAssets: React.FC = () => {
  const queryParams = useQueryParams();
  const feedId = useFeedId();
  const pageParam = queryParams.get('page') || '';
  const [now] = useState(new Date());
  const page = Number.isNaN(parseInt(pageParam, 10))
    ? 1
    : parseInt(pageParam, 10);
  const offset = (page - 1) * PAGE_SIZE;
  const search = queryParams.get(QUERY_PARAM_KEYS.search) || '';
  const [searchValue, setSearchValue] = useState(search || '');
  const mime = queryParams.get('mime') || '';
  const dimension = queryParams.get('dimension') || '';
  const history = useHistory();

  const enableAssetsLibrary = useFeatureFlag(FeatureFlagName.ASSETS_CRUD);

  const [debouncedCallback] = useDebouncedCallback((value: string) => {
    setQueryParams(history, {
      [QUERY_PARAM_KEYS.search]: value,
      page: 1,
    });
  }, 500);

  const filtersApplied = search?.length || mime?.length || dimension?.length;

  const { loading, error, data, previousData } = useQuery<
    SearchableLockableUploads,
    SearchableLockableUploadsVariables
  >(SearchableLockableUploadsQuery, {
    variables: {
      offset,
      perPage: PAGE_SIZE,
      dimension: dimension || null,
      matches: search || null,
      mimeType: mime || null,
      feedId,
      currentDateTime: now.toISOString(),
    },
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    return <div>{error.toString()}</div>;
  }

  const isLoading = loading && !data && !previousData;

  const results = (data || previousData)?.searchLockableUploads
    ?.results as SearchableLockableUploads_searchLockableUploads_results[];

  return (
    <Layout.Page>
      <PageMeta title="Lockable Assets" />
      <PageHeading title="Locked" breadcrumbs={['Assets']}>
        <ButtonList>
          {enableAssetsLibrary && (
            <AppLink
              to={`/${feedId}/assets/compose`}
              styleVariations={[
                ApplinkStyles.PRIMARY_BUTTON,
                ApplinkStyles.LARGE_BUTTON,
              ]}
            >
              Add Asset
            </AppLink>
          )}
        </ButtonList>
      </PageHeading>
      {isLoading ? (
        <div
          css={(theme) => css`
            padding: ${theme.spacing.small} ${theme.spacing.large};
          `}
        >
          <Loader loading={isLoading} />
        </div>
      ) : (
        <React.Fragment>
          <Layout.ContentWrapper
            css={(theme: ThemeType) => css`
              margin: ${theme.spacing.small} 0 0;
            `}
          >
            <form>
              <div
                css={css`
                  display: flex;
                  justify-content: flex-start;
                  flex-wrap: wrap;
                `}
              >
                <SearchInput
                  type="text"
                  placeholder="Search lockable assets"
                  value={searchValue}
                  name="query"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchValue(e.target.value);
                    debouncedCallback(e.target.value);
                  }}
                  onClear={() => {
                    setSearchValue('');
                    debouncedCallback('');
                  }}
                  extraStyles={filterInput}
                />
                <Select
                  css={(theme: ThemeType) => css`
                    flex: 1;
                    background-color: ${theme.colors.white};
                  `}
                  onChange={({ value }: { value: string }) => {
                    setQueryParams(history, { mime: value, page: 1 });
                  }}
                  options={AVAILABLE_MIME_TYPES}
                  value={AVAILABLE_MIME_TYPES.find(
                    ({ value }) => value === mime,
                  )}
                  hasControlBoxShadow
                />
                <Select
                  css={(theme: ThemeType) => css`
                    flex: 1;
                    background-color: ${theme.colors.white};
                    margin-left: 10px;
                  `}
                  onChange={({ value }: { value: string }) => {
                    setQueryParams(history, { dimension: value, page: 1 });
                  }}
                  options={AVAILABLE_SCREEN_RESOLUTIONS}
                  value={AVAILABLE_SCREEN_RESOLUTIONS.find(
                    ({ value }) => value === dimension,
                  )}
                  hasControlBoxShadow
                />
                <Button
                  type="button"
                  size="xxsmall"
                  css={css`
                    margin-left: 10px;
                  `}
                  disabled={!filtersApplied}
                  onClick={() => {
                    setSearchValue('');
                    setQueryParams(history, {
                      page: 1,
                      [QUERY_PARAM_KEYS.search]: '',
                      [QUERY_PARAM_KEYS.mime]: '',
                      [QUERY_PARAM_KEYS.dimension]: '',
                    });
                  }}
                >
                  Clear
                </Button>
              </div>
            </form>
            {results?.length ? (
              <React.Fragment>
                <div
                  css={(theme: ThemeType) => css`
                    display: grid;
                    grid-template-columns: repeat(
                      auto-fill,
                      minmax(16rem, 1fr)
                    );
                    grid-row-gap: 1rem;
                    grid-column-gap: 1rem;
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                    background-color: ${theme.colors.white};
                    border-radius: 8px;
                    padding: 40px;
                    flex-wrap: wrap;
                    box-shadow:
                      0 1px 1px 0 rgba(0, 0, 0, 0.14),
                      0 1px 3px 0 rgba(0, 0, 0, 0.2);
                  `}
                >
                  {results.map(({ upload, thumbnail }) => {
                    const scheduledAsset =
                      upload?.scheduledAssetsByDefaultUploadId.nodes[0];

                    return (
                      <Thumbnail
                        css={css`
                          cursor: pointer;
                          width: 205px;
                          display: flex;
                          figure {
                            align-items: center;
                          }
                          figcaption {
                            text-align: center;
                          }
                        `}
                        key={upload?.id}
                        // @ts-ignore
                        upload={upload}
                        thumbnailSrc={
                          scheduledAsset
                            ? scheduledAsset.scheduledUpload?.signedS3Url
                            : thumbnail?.signedS3Url
                        }
                        onClick={() => {
                          if (enableAssetsLibrary) {
                            history.push(`/${feedId}/assets/${upload?.id}`);
                          }
                        }}
                      />
                    );
                  })}
                </div>
                <Pagination
                  currentPage={page}
                  itemsPerPage={PAGE_SIZE}
                  totalItems={data?.searchLockableUploads?.totalCount ?? 0}
                  onPrevClick={() =>
                    setQueryParams(history, { page: page - 1 })
                  }
                  onNextClick={() =>
                    setQueryParams(history, { page: page + 1 })
                  }
                />
              </React.Fragment>
            ) : (
              <NoMessages
                css={css`
                  margin-top: 45px;
                `}
                icon={!!filtersApplied}
                heading={
                  filtersApplied
                    ? WEB_NO_ASSETS_HEADING_FILTERS
                    : WEB_NO_ASSETS_HEADING
                }
                message={
                  filtersApplied
                    ? WEB_NO_ASSETS_MESSAGE_FILTERS
                    : WEB_NO_ASSETS_MESSAGE
                }
              />
            )}
          </Layout.ContentWrapper>
        </React.Fragment>
      )}
    </Layout.Page>
  );
};

export default LockedAssets;
